footer {
  .cgu {
    font-size: 11px;
    line-height: 14px;
    margin-top: 40px;
    text-align: justify;
  }

  .btn-secondary {
    font-weight: bold;
    // padding: $input-btn-padding-y 40px;
  }

  .opacity {
    opacity: 0.5;
  }
}


.mentions-legales {
  padding: 12px 10px;
  margin: 0 -20px -20px;
  background-color: #f5f4f0;
  font-size: 11px;
  text-align: justify;
  line-height: 1.4;
  font-style: italic;
  color: rgba(#000, .8);
}

.mentions-rgpd{
  padding: 12px 10px;
  margin: 20px 0 0 0;
  font-size: 11px;
  text-align: justify;
  line-height: 1.4;
  font-style: italic;
  color: rgba(#000, .8);
}
