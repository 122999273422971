@font-face {
  font-family: 'glb-icons';
  src: url('../webfonts/glb-icons/glb-icons.eot');
  src: url('../webfonts/glb-icons/glb-icons.eot#iefix') format('embedded-opentype'),
    url('../webfonts/glb-icons/glb-icons.ttf') format('truetype'),
    url('../webfonts/glb-icons/glb-icons.woff') format('woff'),
    url('../webfonts/glb-icons/glb-icons.svg#glb-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="glbicon"],
[class*=" glbicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'glb-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glbicon-consommation:before {
  content: "\e900";
}

.glbicon-coordonnees:before {
  content: "\e901";
}

.glbicon-deplacements:before {
  content: "\e902";
}

.glbicon-equipements:before {
  content: "\e903";
}

.glbicon-famille:before {
  content: "\e904";
}

.glbicon-groupama:before {
  content: "\e905";
}

.glbicon-implication:before {
  content: "\e906";
}

.glbicon-projets:before {
  content: "\e907";
}
