// Question
$mobile-question-margin: 12px;
$question-margin: 10px;

// Question Title
$mobile-question-color: $text-color;
$mobile-question-title-size: 16px;
$mobile-question-title-style: normal;
$mobile-question-title-weight: bold;
$mobile-question-title-lineHeight: normal;
$mobile-question-title-padding: 0 0;
$mobile-question-title-margin: 0 0 14px;
$mobile-question-title-alignment: left;
$question-title-margin: 0 0 14px;


$mobile-question-subtitle-size: 15px;
$mobile-question-subtitle-style: italic;
$mobile-question-subtitle-weight: normal;
// Field
// Field Label
$labelPosition: 'top'; // top or fixed

$form-checkbox-width: 24px;
$form-checkbox-height: $form-checkbox-width;
$form-checkbox-radius: 5px;
$form-checkbox-color: $primary;
$form-checkbox-icon-size: 18px;
$form-checkbox-icon-weight: bold;
$form-checkbox-icon-margin: 25px;
$form-checkbox-icon-top: 0;
$form-checkbox-icon-left: 3px;
$form-checkbox-border-width: 1px;
$form-checkbox-border-style: solid;
$form-checkbox-border-color:#B2B2B2;
$form-checkbox-bg-color: #fff;
// Hover Style checkbox
$form-checkbox-hover-color: $primary;
$form-checkbox-hover-border-width: $form-checkbox-border-width;
$form-checkbox-hover-border-style: $form-checkbox-border-style;
$form-checkbox-hover-border-color: $primary;
$form-checkbox-hover-bg-color: rgba($primary, .5);
// Checked Style checkbox
$form-checkbox-checked-label-color: $primary;
$form-checkbox-checked-icon-color: $primary;
$form-checkbox-checked-border-width: $form-checkbox-border-width;
$form-checkbox-checked-border-style: $form-checkbox-border-style;
$form-checkbox-checked-border-color: $primary;
$form-checkbox-checked-bg-color: rgba($primary, .2);
