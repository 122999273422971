.steps {
  @extend %flexbox;
  @include flex-direction(column);
  padding: $mobile-steps-padding;
  margin: $mobile-steps-margin;

  @if $isIconStyle=='number' {
    counter-reset: section;
  }

  .step {
    @extend %flexbox;
    @include flex(1 0 auto);
    position: relative;
    padding-bottom: $mobile-step-padding-bottom;

    &:last-child {
      @include flex(none);
      padding-bottom: 0;

      .line {
        display: none;
      }
    }

    button {
      padding: 0;

      &.router-link-achieve {
        i {
          background-color: $step-achieve-bg-color;
          color: $step-achieve-icon-color;

          @if $isIconStyle=='sprite' {
            background-position: #{$step-icon-block-width * -1} #{$step-icon-block-height * 0};
          }

          @if $isIconStyle=='fonticon' {
            @if ($stepIconFontFamily=='fontawesome') {
              &:before {
                content: "\F00C";
              }
            }
          }
        }

        span {
          font-style: $step-achieve-text-style;
        }
      }

      &.router-link-active {
        i {
          background-color: $step-active-bg-color;
          color: $step-active-icon-color;

          @if $isIconStyle=='sprite' {
            background-position: #{$step-icon-block-width * 0} #{$step-icon-block-height * 0};
          }

          @if $isIconStyle=='fonticon' {
            @if ($stepIconFontFamily=='fontawesome') {
              &:before {
                content: "\F021";
              }
            }
          }
        }

        span {
          color: $step-active-text-color;
          font-weight: $step-active-text-weight;
          font-style: $step-active-text-style;
        }

        +.line {
          background-color: $step-active-line-bg-color;
        }
      }
    }

    .icon-block {
      @extend %flexbox;
      @include align-items(center);
      position: relative;
      padding: 0;
      z-index: 2;

      i {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        width: $step-icon-block-width;
        height: $step-icon-block-height;
        border-radius: $step-icon-block-radius;

        @if ($stepIsBordered=="yes") {
          border: $step-icon-block-border;
        }

        background-color: $step-bg-color;
        margin-right: 14px;

        @if $isIconStyle=='number' {
          &:before {
            counter-increment: section;
            content: ""counter(section);
          }
        }

        @if $isIconStyle=='sprite' {
          background-image: $step-icon-url;
          background-position: #{$step-icon-block-width * -2} #{$step-icon-block-height * 0};
        }

        @else {
          color: $step-icon-color;
          font-size: $step-icon-size;
          font-weight: $step-icon-weight;
          font-style: normal;


          @if ($stepIconFontFamily=='fontawesome') {
            font-family: 'Font Awesome 5 Pro';

            &:before {
              content: "\F00D";
            }
          }


        }
      }

      span {
        color: $step-text-color;
        font-size: $step-text-size;
        font-weight: $step-text-weight;
        font-style: $step-text-style;
        text-align: $step-text-align;
        @include flex(1);
      }

    }

    .line {
      background-color: $step-line-color;
      width: $step-line-width;
      height: 100%;
      position: absolute;
      top: $step-icon-block-width;
      left: calc(#{$step-icon-block-width} / 2);
    }
  }
}
