a,
button {
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:focus {
    outline: none;
  }
}

.question-wrapper {
  .fullButton {

    .btn.btn-primary {
      width: 100%;
    }
  }

  .btn {
    @extend %flexbox;
    width: 100%;

    span {
      @include flex(1 0 0);
      word-break: break-word;
    }



    &.btn-primary {
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: #B2B2B2;
      color: #444444;
      height: 100%;
      font-size: 14px;
      line-height: 18px;
      padding: 12px 10px;
      @include justify-content(center);
      @include align-items(center);

      @include media-breakpoint-up(lg) {
        width: 170px;
        max-width: 100%;
      }

      &.selected {
        color: #fff;
        background-color: $tertiary;
        border-color: $tertiary;
      }
    }
  }
}

.downloadButton {
  .btn {
    &.btn-primary {
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      background-color: $secondary;
      border: 0;
      width: auto;
    }
  }
}

footer {
  .btn {
    &.btn-primary {
      background-color: $secondary;
      border: $secondary;

      &:hover {
        background-color: $secondary;
        border-color: $secondary;
      }

      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        background-color: $secondary;
        border-color: $secondary;
        box-shadow: 0 0 0 0.2rem rgba($secondary, 0.5);
      }
    }
  }
}
