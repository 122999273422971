// Steps
$mobile-steps-padding: 0;
$mobile-steps-margin: 0;
$steps-padding: 0 60px 30px;
$steps-margin: 20px 0 0;


// Step
$step-text-size: 14px;
$step-text-color: #fff;
$step-text-weight: normal;
$step-text-style: italic;
$step-text-align: left;
$isStepTextWidthConstrained: 'no'; // yes or no if yes => width else text nowrap
$step-text-width: 90px;
$step-bg-color: $primary;
$mobile-step-padding-bottom: 50px;

// Step Icon Block
$step-icon-block-width: 36px;
$step-icon-block-height: $step-icon-block-width;
$step-icon-block-radius: 100%;
$stepIsBordered: 'yes';
$step-icon-block-border: 1px solid #fff;
// Step Icon
$isIconStyle: 'fonticon'; // sprite, number, fonticon
$stepIconFontFamily: 'glbicons'; // fontawesome, glbicons

// if is Number or fonticon
$step-icon-size: 19px;
$step-icon-weight: 300;
$step-icon-color: #fff;
// if is Sprite
$step-icon-url: url("../images/sprite-nav.png");

$step-line-color: #fff;
$step-line-width: 1px;

// Active Step
$step-active-text-color: #fff;
$step-active-text-weight: $step-text-weight;
$step-active-text-style: normal;
$step-active-icon-color: $primary;
$step-active-line-bg-color: $step-line-color;
$step-active-bg-color: #fff;


// Achieve Step
$step-achieve-text-color: #444444;
$step-achieve-text-weight: $step-text-weight;
$step-achieve-text-style: normal;
$step-achieve-icon-color: #fff;
$step-achieve-line-bg-color: $step-line-color;
$step-achieve-bg-color: $tertiary;
