.checkbox-classic {
  .question-title {
    @include flex(1 0 0% !important);
    order: 1 !important;
    max-width: none !important;
    padding: 0 0 0 14px !important;
    font-weight: normal !important;
    margin-bottom: 0 !important;
  }

  .question-wrapper {
    @include flex(0 0 $form-checkbox-width !important);
    padding: 0 !important;
    .field {
      flex-direction: row;
    }
    .checkbox-wrapper {
      width: $form-checkbox-width;
      height: $form-checkbox-height;
      margin-right: 14px;
      + label {
        order: 1;
        flex: 1;
        margin-bottom: 0;
        line-height: 20px;
        a {
          color: $primary;
          font-weight: 600;
          text-decoration: underline;
          margin-left: 4px;
        }
      }
      + p {
        display: contents;
        a {
          color: $primary;
          font-weight: 600;
          text-decoration: underline;
          margin-left: 4px;
        }
      }
    }
    .small{
      label {
        font-size: 13px;
        line-height: 1.2;
      }
    }
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    display: none;
  }

  /* on prépare le label */
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    width: $form-checkbox-width;
    height: $form-checkbox-height;
    margin-bottom: 0;

    position: relative;
    /* permet de positionner les pseudo-éléments */
    padding-left: $form-checkbox-icon-margin;
    /* fait un peu d'espace pour notre case à venir */
    cursor: pointer;
    /* affiche un curseur adapté */
    pointer-events: visible;
  }

  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    width: $form-checkbox-width;
    height: $form-checkbox-height;
    border-radius: $form-checkbox-radius;
    border-color: $form-checkbox-border-color;
    border-width: $form-checkbox-border-width;
    border-style: $form-checkbox-border-style;
    background-color: $form-checkbox-bg-color;
  }

  [type="checkbox"]:checked + label:before {
    background-color: $form-checkbox-checked-bg-color;
    border-color: $form-checkbox-checked-border-color;
    border-width: $form-checkbox-checked-border-width;
    border-style: $form-checkbox-checked-border-style;
  }

  [type="checkbox"]:checked + label {
    color: $form-checkbox-checked-label-color !important;
  }

  /* Aspect général de la coche */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: "\f00c";
    font-family: quote(Font Awesome 5 Pro);
    position: absolute;
    // top: $form-checkbox-icon-top;
    // left: $form-checkbox-icon-left;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $form-checkbox-icon-size;
    font-weight: $form-checkbox-icon-weight;
    color: $form-checkbox-checked-icon-color;
    transition: all 0.2s;
    /* on prévoit une animation */
  }

  /* Aspect si "pas cochée" */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    /* coche invisible */
    transform: scale(0);
    /* mise à l'échelle à 0 */
  }

  /* Aspect si "cochée" */
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    /* coche opaque */
    transform: scale(1);
    /* mise à l'échelle 1:1 */
  }
}

.checkbox-text {
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    display: none;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #b2b2b2;
    cursor: pointer;
    pointer-events: visible;
    text-align: center;
    font-size: 14px;
    border-radius: 8px;
  }

  [type="checkbox"]:checked + label {
    color: #fff;
    background-color: #26735f;
    border-color: #26735f;
  }
  &.required {
    label {
      width: 80px;
      &:after {
        content: "Facultatif";
      }
    }
    [type="checkbox"]:checked + label {
      &:after {
        content: "Obligatoire";
      }
    }
  }
}
