section.content {
  @extend %flexbox;
  @include flex-direction(column);
  @include justify-content(flex-end);
  @include align-items(center);
  background-image: url("../images/prosecure-bkg-mobile.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  @include media-breakpoint-up(sm) {
    background-image: url("../images/prosecure-bkg2.jpg");
    @include flex-direction(row);
    @include align-items(flex-end);
    height: 756px;
  }
  .container {
    max-width: 420px;
    margin: 0;
    position: relative;
    z-index: 1;
  }
}

.banner {
  @extend %flexbox;
  @include justify-content(center);
  color: #fff;
  background-color: #fff;
  margin: -20px -20px 14px;
  width: calc(100% + 40px);
  padding: 0 20px;
  @include media-breakpoint-up(sm) {
    padding: 20px 0px 20px 14px;
    background-color: rgba(#000, .5);
    @include flexbox();
    position: absolute;
    left: 0; bottom: 0;
    margin: 0;
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    padding: 20px 0px 20px 50px;
  }
  .logo {
    width: 220px;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .banner-title {
    display: none;
    @include flex(1);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    @include media-breakpoint-up(sm) {
      display: block;
      text-align: left;
      font-size: 28px;
      line-height: 36px;
    }
  }
  .form-space {
    @include media-breakpoint-up(sm) {
      width: 420px;
      display: block;
      margin: 0 20px;
    }
  }
}
.formulaire-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 8px;
  padding: 0 20px;
}

.formulaire {
  padding: 12px;
  background-color: #fff;
}