body,
#layout {
  // min-height: 100vh;
  // max-height: 100vh;
  // position: relative;
}

#layout {
  @extend %flexbox;
}

section.wrapper {
  @extend %flexbox;
  @include flex-direction(column);
  @include flex(1);
  background-color: #FAFAFA;
  @include media-breakpoint-up(lg) {
    margin-left: $sidebar-width;
  }
}

section.content {
  @include flex(1 0 auto);
  padding: $vertical-layout-padding $horizontal-layout-padding 0;

  @include media-breakpoint-up(sm) {
    padding: 20px $horizontal-layout-padding-lg 0;
  }
}

a {
  color: $text-color;
}
