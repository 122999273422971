@charset "UTF-8";

@import "~normalize.css";
@import "~bootstrap/scss/bootstrap";

// @import './bootstrap/bootstrap';

@import "./vendor/index",
"./config/index",
"./modules/index",
"./components/index",
"./child/index";
