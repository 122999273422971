.field {
  .question-title {
    &.titleAsso {
      // +.question-wrapper {
      //   margin-bottom: 10px;
      // }

      @include media-breakpoint-up(md) {
        @include flex(1 0 30%);

        + .question-wrapper {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.field {
  .field {
    &.fieldTypeVehicule {
      margin-bottom: 4px;
      .question-title {
        @include media-breakpoint-up(lg) {
          flex: 0 0 300px;
          padding-right: 0;
        }
      }
    }
    &.fieldTypeVehiculeOptions {
      @include media-breakpoint-up(lg) {
        margin-left: 318px;
        padding: 0;

        .question-wrapper {
          padding: 0;
        }
      }
    }
    &.fieldNbVehicule {
      margin-bottom: 4px;
      .question-title {
        @include media-breakpoint-up(lg) {
          flex: 0 0 340px;
          padding-right: 0;
        }
      }
    }
    &.fieldNbVehiculeOptions {
      @include media-breakpoint-up(lg) {
        margin-left: 358px;
        padding: 0;
        margin-bottom: 4px;
        .question-wrapper {
          padding: 0;
        }
      }
    }
  }

  &.fieldJugement {
    // @include flex(1 0 0%);
    margin-bottom: 0 !important;

    .question-title {
      @extend %flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include flex(1 0 100%);
      margin-bottom: 0;
      padding-right: 14px;

      @include media-breakpoint-up(lg) {
        @include flex(1);
        text-align: right;
        width: 150px;
      }

      @include media-breakpoint-up(xl) {
        min-width: 320px;
      }
    }

    .question-wrapper {
      margin-top: 6px;
      margin-bottom: 0;
      padding: 0 !important;
      @include flex(1 0 100%);

      @include media-breakpoint-up(lg) {
        @include flex(0 0 auto !important);
        width: auto;
        margin-top: 0;
        height: 100% !important;
      }

      .row {
        height: 100%;

        @include media-breakpoint-up(lg) {
          width: auto;
        }

        // .col>div {
        //   height: auto;

        //   @include media-breakpoint-up(lg) {
        //     height: 100%;
        //   }
        // }

        .btn {
          padding-left: 20px;
          padding-right: 20px;
          // background-color: red;

          @include media-breakpoint-up(lg) {
            width: auto;
          }

          @include media-breakpoint-up(xl) {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }

    & + .field {
      .question-title {
        display: none;
        color: $tertiary;
        padding-right: 20px;
        padding-bottom: 0.5rem !important;
        @include flex(0 0 auto);
        width: auto;
        @include align-items(center);
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          @include flexbox();
        }
      }

      .question-wrapper {
        padding: 0;
      }
    }
  }

  &.fieldAssurance {
    .question-title {
      @include media-breakpoint-up(lg) {
        min-width: 276px;
      }

      @media screen and (min-width: 1480px) {
        min-width: 550px;
      }
    }

    .question-wrapper .row .btn {
      padding-left: auto;
      padding-right: auto;

      @include media-breakpoint-up(lg) {
        width: 170px;
      }
    }
  }

  & + .fieldAssuranceDetenu {
    .question-wrapper {
      height: 100% !important;

      .row {
        height: 100% !important;
      }

      .btn.btn-primary {
        width: 100%;

        @include media-breakpoint-up(xl) {
          width: 180px;
          max-width: 100%;
        }
      }
    }
  }
}
