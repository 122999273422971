.field {
  label {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .form-control {
    height: auto;
    padding: 4px 8px;
    font-size: 14px;
  }
  textarea.form-control {
    height: 40px;
  }
}
.intro {
  font-size: 16px;
  margin-bottom: 40px;
}

.field-repeatable-wrapper {
  @media screen and (min-width: 1480px) {
    max-width: 80%;
  }
}

.field-repeatable {
  @include align-items(stretch !important);
  background-color: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  padding: 10px;

  @include media-breakpoint-down(md) {
    margin-bottom: 24px !important;
  }

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  .col {
    padding: 3px 6px;
  }

  .question-title {
    @extend %flexbox;
    @include align-items(center);
    height: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0 !important;
    margin-bottom: 6px;
    font-weight: normal;

    @include media-breakpoint-up(xl) {
      border-right: 1px solid #b2b2b2;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .question-wrapper {
    @include media-breakpoint-up(xl) {
      padding: 6px 20px !important;
    }
  }

  .btn.btn-primary {
    width: auto;
    padding: 5px 7px;

    @include media-breakpoint-down(md) {
      position: absolute;
      top: -16px;
      right: -10px;
      height: auto;
      padding: 6px 10px;
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      border: none;
    }
  }
}

.field.alignTop > .question-title {
  padding-top: 18px;
}

@include media-breakpoint-up(md) {
  .borderBottom {
    .form-control,
    .v-select .vs__dropdown-toggle {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 0;
      border-bottom: 1px solid #b2b2b2;
      border-radius: 0;

      padding-left: 0;
      padding-right: 0;
    }

    .number-input__input {
      border: 0 !important;
      border-bottom: 1px solid #b2b2b2 !important;
      border-radius: 0 !important;
    }
  }
}

.number-input.number-input--inline {
  display: flex !important;
}

.number-input.number-input--inline,
.number-input__input {
  width: 100% !important;
}

.nb-petits-enfants {
  padding: 0;
  flex: 0 0 auto;
  width: auto;

  .question-title {
    max-width: none !important;
    flex: 0 0 auto !important;
    margin-bottom: 0;
  }

  .question-wrapper {
    @include flex(1 0 auto !important);

    .field {
      width: 70px;

      input {
        text-align: right;
      }
    }
  }
}

.field .field.inline.types-services-text {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    margin-top: 14px;
  }

  .question-wrapper,
  .row {
    height: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
