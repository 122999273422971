header {
  background: $header-bg;
  background-size: cover;
  @extend %flexbox;
  @include align-items(center);
  padding: $vertical-header-padding $horizontal-header-padding;
  // position: sticky;
  // top: 0;
  // z-index: 2;

  @include media-breakpoint-up(sm) {
    padding: $vertical-header-padding-lg $horizontal-header-padding-lg;
    // position: static;
  }

  @include media-breakpoint-up(xl) {
    padding: $vertical-header-padding-xl $horizontal-header-padding-xl;
  }

  .header-title {
    @extend %flexbox;
    @include align-items(center);
    background-color: $header-title-bg;
    color: $header-title-color;
    font-size: $header-title-size;
    font-weight: bold;
    padding: $header-title-padding;

    i {
      margin-right: $header-title-icon-mr;
      font-size: $header-title-icon-size;
    }

    @include media-breakpoint-up(sm) {
      padding: $header-title-padding-lg;
      font-size: $header-title-size-lg;

      i {
        margin-right: $header-title-icon-mr-lg;
        font-size: $header-title-icon-size-lg;
      }
    }

  }

  .btn-menu {
    color: $btn-menu-color;
    background-color: $btn-menu-bg-color;
    padding: $btn-menu-padding;
    width: $btn-menu-width;
    height: $btn-menu-height;
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);

    i {
      font-size: $btn-menu-icon-size;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}


.subheader-title {
  color: $subheader-title-color;
  font-size: $subheader-title-size;
  font-weight: $subheader-title-weight;
  margin: $subheader-title-margin;
  text-align: center;
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    margin-bottom: 74px;
  }

  @if $isTitleHorizontalLines=='yes' {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);

    div {
      margin: 0 $subheader-title-lines-margin;
    }

    &:before,
    &:after {
      content: "";
      @include flexbox();
      flex-grow: $subheader-title-lines-flex-grow;
      flex-basis: $subheader-title-lines-flex-basis;
      height: $subheader-title-lines-height;
      background-color: $subheader-title-lines-color;

      @if $isTitleHorizontalLinesBordered=='yes' {
        border-bottom: $subheader-title-lines-border;
      }
    }

  }
}
