.mx-datepicker {
  .mx-input-wrapper {
    .mx-input {
      background-color: $input-bg;
      border: $input-border-color solid $input-border-width;
      padding: $input-padding-y $input-padding-x;
      border-radius: $input-border-radius;
      font-size: $input-font-size;
      line-height: $input-line-height;
      height: auto;
      color: $input-color;
    }
  }
}
