$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
) !default;

$primary: #26735F;
$secondary: #FF7B00;
$tertiary: #44A993;
$quaternary: #11594b;

$text-color: #4D4F5C;

$btn-font-size: 18px;

$grid-row-columns: 12;

$vertical-layout-padding: 20px;
$horizontal-layout-padding: 20px;
$vertical-layout-padding-lg: 30px;
$horizontal-layout-padding-lg: 20px;
$vertical-layout-padding-xl: 30px;
$horizontal-layout-padding-xl: 45px;
