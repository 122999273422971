.confirmation-block {
  margin-top: 30px;
  margin-bottom: 60px;
  a {
    color: $gray-900;
    font-weight: bold;
  }
  .icon-confirmation + .confirm-message {
    margin-top: 30px;
  }
  .confirm-message {
    font-size: 17px;
    font-weight: 600;
    color: $gray-900;
    margin-bottom: 12px;
  }
  .confirm-question {
    font-size: 18px;
    font-weight: 700;
  }
  p + .confirm-question {
    margin-top: 60px;
  }
  &.error {
    i {
      font-size: 80px;
      margin-bottom: 30px;
      color: #ba2626;
    }
    .confirm-message {
      color: #ba2626;
      font-weight: bold;
    }
  }
}

.confirmation-footer {
  i.arrow.right {
    margin-left: 8px;
  }
}
