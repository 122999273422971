#sidebar {
  position: fixed;
  z-index: 3;
  display: none;

  @include media-breakpoint-up(lg) {
    @include flexbox();
  }

  height: 100vh;

  &.menu-opened {
    @extend %flexbox;

    #sidebar-nav {
      animation-name: fadeInLeft;
    }

    #sidebar-mask {
      animation-name: fadeIn;
    }
  }

  &.menu-closed {
    @extend %flexbox;

    #sidebar-nav {
      animation-name: fadeOutLeft;
    }

    #sidebar-mask {
      animation-name: fadeOut;
    }
  }

  #sidebar-nav {
    background-color: $primary;
    padding: 10px 20px;
    width: $sidebar-width;
    text-align: center;
    max-height: 100vh;
    overflow: auto;
    position: relative;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      box-shadow: 0px 0 20px -4px rgba(#000, 0.4);
    }

    #logo {
      margin-bottom: 20px;
      padding: 12px 16px 12px 12px;
      background-color: #fff;
      width: 90%;
    }
  }

  #sidebar-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(darken($primary, 20%), 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
