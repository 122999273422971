.steps {
  .step {
    button {
      @extend %flexbox;
      width: 100%;

      .icon-block {
        width: 100%;

        i {
          @include flex(0 0 auto);

          span {
            @include flex(1 0 0%);
          }
        }
      }
    }
  }
}
