* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.container {
  padding: 10px 14px 0;
  background-color: rgba(#fff, .8);
}

img {
  max-width: 100%;
}
