.field {
  @extend %flexbox;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  margin-bottom: $mobile-question-margin;

  @include media-breakpoint-up(sm) {
    margin-bottom: $question-margin;
  }

  .field {
    @extend %flexbox;
    @include flex-direction(column);
    position: relative;
    margin-bottom: 0;

    > div {
      height: 100%;
      max-width: 100%;
    }

    @if $labelPosition== "fixed" {
      input {
        padding: 1.4rem 0.75rem 0.3rem;

        &:focus,
        &.filled {
          ~ label {
            font-size: 11px;
            top: 8px;
          }
        }

        + label {
          pointer-events: none;
          position: absolute;
          top: 16px;
          left: 1.75rem;
          transition: all 0.2s;
        }
      }
    } @else {
      label {
        order: -1;
      }
    }
    &.inline {
      margin-bottom: 14px;
      .question-wrapper {
        height: auto;
        padding: 0;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          padding: 0 10px;
        }
      }
      .question-title {
        &.longTitle {
          @include media-breakpoint-up(lg) {
            @include flex(0 0 50%);
          }
        }
      }
      &.mobileInline {
        margin-bottom: 14px;
        .question-title {
          @include media-breakpoint-up(sm) {
            @include flex(0 0 auto);
            max-width: 100%;
            margin-bottom: 0;
          }
          @include media-breakpoint-up(lg) {
            max-width: 70%;
          }
          @include media-breakpoint-up(xl) {
            max-width: none;
          }
        }
      }
    }
  }

  &.noBottom {
    margin-bottom: 0 !important;
  }

  &.noPadding {
    .question-wrapper {
      padding: 0;
    }
  }

  &.center {
    margin: 0 auto;
  }

  &.inline {
    @include flex-direction(row);
    @include align-items(center);

    // Question
    .question-wrapper {
      @include media-breakpoint-up(md) {
        @include flexbox();
        @include align-items(center);
        @include flex(1 0 0%);
      }

      .question-wrapper {
        @include media-breakpoint-up(md) {
          @include flex(1 0 0%);
        }
      }

      [class*="grid-"] {
        @include justify-content(flex-start);
      }
    }

    .question-title {
      @include flex(1 0 100%);
      padding-right: 14px;

      @include media-breakpoint-up(md) {
        @include flex(0 0 auto);
        max-width: 60%;
        margin-bottom: 0;
      }

      &.bold {
        font-weight: bold;
      }

      &.textRight {
        @include media-breakpoint-up(xl) {
          flex-basis: 50%;
          text-align: right;
        }
      }
    }

    // Subquestion

    .form-control {
      @include flex(1 0 auto);
      margin-bottom: 0;
    }

    &.small-label {
      label {
        font-size: 14px;
      }
    }
  }

  &.suffixed {
    label {
      order: 1;
      padding-right: 0;
      padding-left: 14px;
    }
  }

  &.pricingTable {
    .question-title {
      margin-bottom: 60px;
      font-weight: 400;
    }
  }

  .question-wrapper {
    @include flex(1 1 0);
    .question-wrapper {
      margin-bottom: $mobile-question-margin;
      @include media-breakpoint-up(sm) {
        margin-bottom: $question-margin;
      }
    }
  }

  .question-title {
    // @include flex(1 0 0);
    @include justify-content($mobile-question-title-alignment);
    text-align: $mobile-question-title-alignment;
    z-index: 1;
    font-size: $mobile-question-title-size;
    font-style: $mobile-question-title-style;
    font-weight: $mobile-question-title-weight;
    line-height: $mobile-question-title-lineHeight;
    margin: $mobile-question-title-margin;
    padding: $mobile-question-title-padding;
    color: $mobile-question-color;

    @include media-breakpoint-up(md) {
      margin: $question-title-margin;
    }

    .subtitle {
      font-size: $mobile-question-subtitle-size;
      font-weight: $mobile-question-subtitle-weight;
      font-style: $mobile-question-subtitle-style;
    }
  }

  > .question-wrapper {
    @include media-breakpoint-up(lg) {
      padding: 0 10px;
    }
  }

  &.checkbox {
    @extend %flexbox;
    @include flex-wrap(wrap);
  }
}

.field-backgrounded {
  background-color: #f5f4f0;
  padding: 40px 80px 60px;

  > .question-title {
    color: $quaternary;
  }
}

@for $j from 1 through 1000 {
  .field.form-control-width-#{$j} {
    .form-control {
      @include flex(0 0 (($j) * 1px));
    }
  }
}

.titleLabel .question-title {
  font-size: 19px;
  font-weight: 600;
  color: $text-color;
  margin-bottom: 0;
}

.field {
  &.subQuestion {
    margin-bottom: 14px;

    .question-title {
      @include flex(1 0 100%);
      font-weight: normal;

      @include media-breakpoint-up(lg) {
        @include flex(0 0 30%);
        text-align: right;
      }
    }
  }

  &.subQuestion-indent {
    .field {
      .question-title {
        @include media-breakpoint-up(xl) {
          margin-left: 80px;
        }
      }
    }
  }
}
